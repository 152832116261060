
export default {
    setOption(echarts, option) {
        const doc = document.getElementById("unitData")
        let myChart = echarts.getInstanceByDom(doc);
        if (myChart == undefined) {
            myChart = echarts.init(doc); // 基于准备好的dom，初始化echarts实例
        }
        myChart.clear(); // 清除画布
        doc.setAttribute('_echarts_instance_', '')
        myChart.setOption(option); // 使用刚指定的配置项和数据显示图表。
    },

    setUnitDataChart(echarts,nameList,deviceList,userList) {
        var option = {
            tooltip: {
              // backgroundColor: "rgba(255,255,255,0.8)",
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
              textStyle: {
                // color: "#fff",
              },
            },
            grid: {
              left: '1%',
              right: '1%',
              top: '12%',
              bottom: '1',
              containLabel: true,
            },
            xAxis: [
              {
                type: 'category',
                data: nameList,
                axisTick: {
                  alignWithLabel: true
                },
                axisLine: {
                    lineStyle: {           
                        // color: '#fff'
                    }
                  }
              }
            ],
            yAxis: [
              {
                type: 'value',
                splitLine:{
                  show: false
                },
                axisLine: {
                    lineStyle: {           
                        // color: '#fff'
                    }
                  }
              }
            ],
            series: [
              {
                name: '用户数',
                type: 'bar',
                emphasis: {
                  focus: 'series'
                },
                data: userList
              },
              {
                name: '设备数',
                type: 'bar',
                emphasis: {
                  focus: 'series'
                },
                data: deviceList
              },
            ]
          };

        this.setOption(echarts, option);
    },

    setUnitDataFullScreenChart(echarts,nameList,deviceList,userList) {
      var option = {
          tooltip: {
            // backgroundColor: "rgba(255,255,255,0.8)",
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            textStyle: {
              // color: "#fff",
            },
          },
          grid: {
            left: '1%',
            right: '1%',
            top: '12%',
            bottom: '1',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              data: nameList,
              axisTick: {
                alignWithLabel: true
              },
              axisLine: {
                  lineStyle: {           
                      color: '#fff'
                  }
                }
            }
          ],
          yAxis: [
            {
              type: 'value',
              splitLine:{
                show: false
              },
              axisLine: {
                  lineStyle: {           
                      color: '#fff'
                  }
                }
            }
          ],
          series: [
            {
              name: '用户数',
              type: 'bar',
              emphasis: {
                focus: 'series'
              },
              data: userList
            },
            {
              name: '设备数',
              type: 'bar',
              emphasis: {
                focus: 'series'
              },
              data: deviceList
            },
          ]
        };

      this.setOption(echarts, option);
  },

};