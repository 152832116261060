/* eslint-disable */

export default {

  getContent(data) {
    return `
          <div style="width:300px;">
            <div style="background-color: #285df0;height: 80px;padding: 5px 0px;border-radius: 0px 0px 0px 0px;">
                <div style="margin-left: 10px;height: 50%;display: flex;">
                  <span style="font-size: 15px;color: white;align-self: center;">用户姓名：${data.name}</span>
                </div>
                <div style="margin-left: 10px;height: 50%;display: flex;">
                  <span style="font-size: 15px;color: white;align-self: center;">身份证：${data.idcard}</span>
                </div>
            </div>

            <div style="background-color: white;height: 100px;">
              <div style="margin-top:10px;margin-left: 10px;height: 100%;display: flex;">
                <span style="font-size: 13px;color: black;display: flex;">详细地址：${data.address}</span>
              </div>
            </div>

          </div>
          <div style="width: 0; height: 0;
                      border-left: 10px solid transparent;
                      border-right: 10px solid transparent;
                      border-top: 10px solid #FFF;
                      position: relative;
                      left: 50%;
                      margin-left: -10px;">
          </div>
            `;
  }

}

