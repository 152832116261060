/* eslint-disable */

export default {

  getSmartDeviceContent(data) {
    return `
          <div style="width:300px;">
            <div style="background-color: #285df0;height: 80px;padding: 5px 0px;border-radius: 0px 0px 0px 0px;">
                <!--<div class="el-icon-close" style="color:white;position:absolute;right:10px" @click='closeInfoWindow()'></div>-->
                <div style="margin-left: 10px;height: 34%;display: flex;">
                  <span style="font-size: 15px;color: white;align-self: center;">绑定用户：${data.userName}</span>
                </div>
                <div style="margin-left: 10px;height: 33%;display: flex;">
                  <span style="font-size: 15px;color: white;align-self: center;">设备名称：${data.deviceName}</span>
                </div>
                <div style="margin-left: 10px;height: 33%;display: flex;">
                  <span style="font-size: 15px;color: white;align-self: center;">设备型号：${data.deviceModel}</span>
                </div>
            </div>

            <div style="background-color: white;height: 180px;">
              <div style="margin-left: 10px;margin-top:10px;height: 14%;display: flex;">
                <span style="font-size: 13px;color: black;display: flex;">设备编号：${data.deviceId}</span>
              </div>
              <div style="margin-left: 10px;height: 14%;display: flex;">
                <span style="font-size: 13px;color: black;display: flex;">设备电量：${data.elec}</span>
              </div>
              <div style="margin-left: 10px;height: 14%;display: flex;" >
                <span style="font-size: 13px;color: black;display: flex;">设备状态：${data.onLineStr}</span>
              </div>
              <div style="margin-left: 10px;height: 14%;display: flex;">
                <span style="font-size: 13px;color: black;display: flex;">安装名称：${data.positionName}</span>
              </div>
              <div style="margin-left: 10px;height: 20%;display: flex;">
                <span style="font-size: 13px;color: black;display: flex;">安装位置：${data.address}</span>
              </div>
            </div>

          </div>
          <div style="width: 0; height: 0;
                      border-left: 10px solid transparent;
                      border-right: 10px solid transparent;
                      border-top: 10px solid #FFF;
                      position: relative;
                      left: 50%;
                      margin-left: -10px;">
          </div>
            `;
  },

  getWearDeviceContent(data) {
    return `
          <div style="width:300px;">
            <div style="background-color: #285df0;height: 80px;padding: 5px 0px;border-radius: 0px 0px 0px 0px;">
                <!--<div class="el-icon-close" style="color:white;position:absolute;right:10px" @click='closeInfoWindow()'></div>-->
                <div style="margin-left: 10px;height: 34%;display: flex;">
                  <span style="font-size: 15px;color: white;align-self: center;">绑定用户：${data.userName}</span>
                </div>
                <div style="margin-left: 10px;height: 33%;display: flex;">
                  <span style="font-size: 15px;color: white;align-self: center;">设备名称：${data.deviceName}</span>
                </div>
                <div style="margin-left: 10px;height: 33%;display: flex;">
                  <span style="font-size: 15px;color: white;align-self: center;">设备型号：${data.deviceModel}</span>
                </div>
            </div>

            <div style="background-color: white;height: 170px;">
      
              <div style="margin-left: 10px;margin-top:10px;height: 14%;display: flex;">
                <span style="font-size: 13px;color: black;display: flex;">设备编号：${data.deviceId}</span>
              </div>
              <div style="margin-left: 10px;height: 14%;display: flex;">
                <span style="font-size: 13px;color: black;display: flex;">设备电量：${data.elec}</span>
              </div>
              <div style="margin-left: 10px;height: 14%;display: flex;" >
                <span style="font-size: 13px;color: black;display: flex;">设备状态：${data.onLineStr}</span>
              </div>
              <div style="margin-left: 10px;height: 20%;display: flex;">
                <span style="font-size: 13px;color: black;display: flex;">详细地址：${data.address}</span>
              </div>
            </div>

          </div>
          <div style="width: 0; height: 0;
                      border-left: 10px solid transparent;
                      border-right: 10px solid transparent;
                      border-top: 10px solid #FFF;
                      position: relative;
                      left: 50%;
                      margin-left: -10px;">
          </div>
            `;
  }

}

