/**
 * 设备类型
 */

// import * as echarts from 'echarts'

export default {
    setHealthDataOption(echarts, option) {
        const doc = document.getElementById("healthDataPie")
        let myChart = echarts.getInstanceByDom(doc);
        if (myChart == undefined) {
            myChart = echarts.init(doc); // 基于准备好的dom，初始化echarts实例
        }
        myChart.clear(); // 清除画布
        doc.setAttribute('_echarts_instance_', '')
        myChart.setOption(option); // 使用刚指定的配置项和数据显示图表。
    },

    setHealthDataChart(echarts,data) {
      var option = {
        color: ['#35EFE0', '#0075FD', '#9383F6', '#FF9452', '#FA7F97'],//配置颜色
        tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: {c} ({d}%)",
            position: [10, 1],
        },
        legend: {
            orient: 'vertical',
            left: 'right',
            itemWidth:8,
            itemHeight:8,
            // itemGap:15,
            padding:5
        },
        series: [
            {
            name: '健康数据',
            type: 'pie',
            radius: ['10%', '80%'],
            center: ['40%', '50%'],
            // avoidLabelOverlap: false,
            // selectedOffset : -5,
            itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                // show: false,
                // position: 'center',
                formatter: '{d}%',//设置左边图上的%
                position: 'inner',
                fontSize: 14,
                color:'#FFFFFF'
            },
            // emphasis: {
            //     label: {
            //         show: true,
            //         fontSize: '40',
            //         fontWeight: 'bold'
            //     }
            // },
            labelLine: {
                normal: {
                    show: false
                }
            },
            data: data
            }
        ]
      };

      this.setHealthDataOption(echarts,option);
    },

    setHealthDataFullScreenChart(echarts,data) {
        var option = {
          color: ['#35EFE0', '#0075FD', '#9383F6', '#FF9452', '#FA7F97'],//配置颜色
          tooltip: {
              trigger: 'item',
              formatter: "{a} <br/>{b}: {c} ({d}%)",
              position: [10, 1],
          },
          legend: {
              orient: 'vertical',
              left: 'right',
              itemWidth:8,
              itemHeight:8,
              // itemGap:15,
              padding:5,
              textStyle :{
                color:'#FFFFFF'
             }
          },
          series: [
              {
              name: '健康数据',
              type: 'pie',
              radius: ['10%', '80%'],
              center: ['40%', '50%'],
              // avoidLabelOverlap: false,
              // selectedOffset : -5,
              itemStyle: {
                  borderRadius: 10,
                  borderColor: '#fff',
                  borderWidth: 2
              },
              label: {
                  // show: false,
                  // position: 'center',
                  formatter: '{d}%',//设置左边图上的%
                  position: 'inner',
                  fontSize: 14,
                  color:'#FFFFFF'
              },
              // emphasis: {
              //     label: {
              //         show: true,
              //         fontSize: '40',
              //         fontWeight: 'bold'
              //     }
              // },
              labelLine: {
                  normal: {
                      show: false
                  }
              },
              data: data
              }
          ]
        };
  
        this.setHealthDataOption(echarts,option);
      },
};