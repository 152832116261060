/**
 * 设备类型
 */

// import * as echarts from 'echarts'

export default {
    setOption(echarts, option) {
          const doc = document.getElementById("warningReportData")
          let myChart = echarts.getInstanceByDom(doc);
          if (myChart == undefined) {
              myChart = echarts.init(doc); // 基于准备好的dom，初始化echarts实例
          }
          myChart.clear(); // 清除画布
          doc.setAttribute('_echarts_instance_', '')
          myChart.setOption(option); // 使用刚指定的配置项和数据显示图表。
          window.onresize = myChart.resize
      },
  
      setWarningReportChart(echarts,data,dateType,year) {
        var xdata = [];
        if(1 == dateType) {
          if (data.length == 30) {
            xdata = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17',
              '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'
            ]
          } else if (data.length == 31) {
            xdata = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17',
              '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
            ]
          } else if (data.length == 29) {
            xdata = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17',
              '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29'
            ]
          } else if (data.length == 28) {
            xdata = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17',
              '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'
            ]
          }
        }else if(2 == dateType){
          xdata = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
        }else if(3 == dateType){
          xdata = ['一季度', '二季度', '三季度', '四季度'];
        }else if(4 == dateType){
          xdata = [year-3, year-2, year-1, year];
        }
       

        var option = {
          tooltip: {
              trigger: 'axis'
            },
            color: ['#93adf7'],
            grid: {
              left: '1%',
              right: '1%',
              top: '12%',
              bottom: '1',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                boundaryGap: false,
                data: xdata,
                axisLine: {
                  lineStyle: {           
                      // color: '#fff'
                  }
                }
              }
            ],
            yAxis: [
              {
                type: 'value',
                splitLine:{
                  show: false
                },
                axisLine: {
                  lineStyle: {           
                      // color: '#fff'
                  }
                }
              }
            ],
            series: [
              {
                name: '总数',
                type: 'line',
                stack: 'Total',
                smooth: true,
                lineStyle: {
                  width: 2,
                  color: '#9383F6'
                },
                showSymbol: true,
                areaStyle: {
                  opacity: 0.8,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#0CB6FF'
                    },
                    {
                      offset: 1,
                      color: '#6ADCF5'
                    }
                  ])
                },
                emphasis: {
                  focus: 'series'
                },
                data: data
              }
            ]
      };
  
      this.setOption(echarts,option);
      },

      setWarningReportFullScreenChart(echarts,data,dateType,year) {
        var xdata = [];
        if(1 == dateType) {
          if (data.length == 30) {
            xdata = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17',
              '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'
            ]
          } else if (data.length == 31) {
            xdata = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17',
              '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
            ]
          } else if (data.length == 29) {
            xdata = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17',
              '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29'
            ]
          } else if (data.length == 28) {
            xdata = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17',
              '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'
            ]
          }
        }else if(2 == dateType){
          xdata = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
        }else if(3 == dateType){
          xdata = ['一季度', '二季度', '三季度', '四季度'];
        }else if(4 == dateType){
          xdata = [year-3, year-2, year-1, year];
        }
       

        var option = {
          tooltip: {
              trigger: 'axis'
            },
            color: ['#93adf7'],
            grid: {
              left: '1%',
              right: '1%',
              top: '12%',
              bottom: '1',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                boundaryGap: false,
                data: xdata,
                axisLine: {
                  lineStyle: {           
                      color: '#fff'
                  }
                }
              }
            ],
            yAxis: [
              {
                type: 'value',
                splitLine:{
                  show: false
                },
                axisLine: {
                  lineStyle: {           
                      color: '#fff'
                  }
                }
              }
            ],
            series: [
              {
                name: '总数',
                type: 'line',
                stack: 'Total',
                smooth: true,
                lineStyle: {
                  width: 2,
                  color: '#9383F6'
                },
                showSymbol: true,
                areaStyle: {
                  opacity: 0.8,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#0CB6FF'
                    },
                    {
                      offset: 1,
                      color: '#6ADCF5'
                    }
                  ])
                },
                emphasis: {
                  focus: 'series'
                },
                data: data
              }
            ]
      };
  
      this.setOption(echarts,option);
      },
  };